<template>
  <b-container
    class="mt-2"
    fluid
  >
    <!-- Filters -->
    <b-row>
      <b-col
        cols="12"
        class="px-1"
      >
        <b-form-group
          label="Search"
          label-for="filter_pending_search"
          label-class="font-weight-bolder"
        >
          <b-input
            id="filter_pending_search"
            v-model="tablePending.filter.search"
            type="text"
            size="sm"
            placeholder="search here"
            debounce="1000"
            autocomplete="off"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <!-- Table -->
    <b-row
      v-if="currentTabIndex === 0"
      class="mt-1"
    >
      <b-col
        cols="12"
        class="px-1"
      >
        <b-table
          ref="tablePending"
          small
          hover
          striped
          bordered
          responsive
          show-empty
          :stacked="isMobile()"
          :per-page="tablePending.perPage"
          :current-page="tablePending.currentPage"
          :items="tablePendingProvider"
          :fields="tablePending.fields"
          :sort-by.sync="tablePending.sortBy"
          :sort-desc.sync="tablePending.sortDesc"
          :sort-direction="tablePending.sortDirection"
          :filter="tablePending.filter"
          :filter-included-fields="tablePending.filterOn"
          :busy="tablePending.busy"
        >
          <template #cell(index)="row">
            {{ tablePending.currentPage * tablePending.perPage - tablePending.perPage + (row.index + 1) }}
          </template>

          <template #cell()="row">
            <div class="text-md-nowrap">
              {{ row.value }}
            </div>
          </template>

          <template #cell(voucher.voucher_number)="row">
            <div class="text-md-nowrap">
              <b>{{ row.value }}</b>
            </div>
          </template>

          <template #cell(amount)="row">
            <div
              class="text-md-nowrap text-md-right"
              style="min-width: 100px;"
            >
              {{ row.value }}
            </div>
          </template>

          <template #cell(action)="row">
            <div class="text-md-nowrap d-flex flex-column flex-md-row justify-content-md-start justify-content-center">
              <b-button
                size="sm"
                :disabled="busy"
                class="mr-0 mr-md-1 mb-1 mb-md-0"
                @click="onView(row.item)"
              >
                View
              </b-button>
              <b-button
                v-if="Number(row.item.stage) <= 2"
                size="sm"
                variant="success"
                class="mr-0 mr-md-1 mb-1 mb-md-0"
                :disabled="busy"
                @click="onRenotify(row.item)"
              >
                Renotify
              </b-button>
              <b-button
                v-if="Number(row.item.stage) <= 3"
                size="sm"
                variant="outline-danger"
                :disabled="busy"
                @click="onCancel(row.item)"
              >
                Cancel
              </b-button>
            </div>
          </template>

          <template #table-busy>
            <div class="text-center py-5">
              <b-icon
                icon="stopwatch"
                font-scale="5"
                animation="cylon"
              />
              <p class="h3 py-2">
                <strong>Loading . . .</strong>
              </p>
            </div>
          </template>

        </b-table>
      </b-col>

      <b-col
        cols="12"
        md="6"
        class="mt-1 mb-2 px-1"
      >
        <b-select
          v-model="tablePending.perPage"
          size="sm"
          class="w-100 w-md-25"
          :options="tablePending.pageOptions"
        />
      </b-col>

      <b-col
        cols="12"
        md="6"
        class="d-flex justify-content-center justify-content-md-end mt-1 px-1"
      >
        <b-pagination
          v-model="tablePending.currentPage"
          pills
          last-number
          first-number
          prev-text="Prev"
          next-text="Next"
          aria-controls="table"
          :total-rows="tablePending.totalRows"
          :per-page="tablePending.perPage"
        />
      </b-col>

    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { UserExpensesPRPendingService } from '@/services'
import formatter from '@/mixins/formatter'
import misc from '@/mixins/misc'

export default {

  name: 'UserExpensesPaymentRequestsPending',

  mixins: [formatter, misc],

  props: {
    busy: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    currentTabIndex: {
      type: Number,
      default: () => {
        return 0
      }
    }
  },

  data () {
    return {
      tablePending: {
        selectedRowIndex: null,
        perPage: 10,
        pageOptions: [5, 10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: null,
        sortDesc: false,
        sortDirection: 'asc',
        filter: {
          search: null
        },
        filterOn: [],
        busy: false,
        fields: [
          { mobile: 0, key: 'index', label: '#' },
          { mobile: 13, key: 'action', class: 'text-center' },
          { mobile: 1, key: 'created_at', label: 'Requested At', formatter: this.dateTimeShortFormatter, sortable: true },
          { mobile: 2, key: 'voucher.voucher_number', label: 'Voucher Number' },
          { mobile: 3, key: 'bp_name', label: 'Business Partner' },
          { mobile: 4, key: 'customer_name', label: 'Cash Advance' },
          { mobile: 5, key: 'bp_transaction_type', label: 'Transaction Type' },
          { mobile: 6, key: 'mop', label: 'Mode of Payment' },
          { mobile: 7, key: 'amount', formatter: this.numberFormatter },
          { mobile: 8, key: 'stage', label: 'Current Approver', formatter: this.userCurrentApprover },
          { mobile: 9, key: 'approvals', label: 'Time Lapsed', formatter: this.getTimeDifferenceFromLastApprover },
          { mobile: 10, key: 'last_seen', label: 'Last Seen', formatter: this.getLastApproverSeen },
          { mobile: 11, key: 'status' },
          { mobile: 12, key: 'updated_at', formatter: this.dateTimeShortFormatter }
        ].sort(this.scaleOnMobile)
      }
    }
  },

  mounted () {
    core.index()
  },

  methods: {

    async tablePendingProvider ({ currentPage, perPage, sortBy, sortDesc, filter }) {
      this.tablePending.busy = true
      return await UserExpensesPRPendingService.get(
        this.objectToUrl({
          page: currentPage,
          per_page: perPage,
          sort: sortBy,
          sort_desc: sortDesc,
          filter_text: filter.search
        })
      ).then(({ data }) => {
        this.tablePending.totalRows = data.total_rows
        return data.items
      }).catch(() => []).finally(() => {
        this.tablePending.busy = false
      })
    },

    onView (paymentRequest) {
      this.$emit('onView', paymentRequest)
    },

    onEdit (paymentRequest) {
      this.$emit('onEdit', {
        ...paymentRequest,
        is_drafting: false
      })
    },

    onCancel (paymentRequest) {
      this.swalConfirmWithRemarks({
        html: '<h6>Mark this Request as Canceled?</h6>',
        confirmButtonText: 'Mark as Canceled',
        cancelButtonText: 'Discard',
        remarksRequired: true,
        customClass: {
          confirmButton: 'btn btn-sm btn-danger'
        },
        preConfirm: remarks => this.onPut({
          id: paymentRequest.id,
          canceled_remarks: remarks,
          status: 'Canceled'
        }).then(({ message }) => {
          this.swalSuccess(message)
        })
      })
    },

    onRenotify (paymentRequest) {
      const approver = paymentRequest.approvals.find(
        approver => Number(approver.stage) === Number(paymentRequest.stage)
      )
      const approverName = approver?.approver_name || approver?.approver_label || 'Current Approver'
      this.swalConfirm({
        html: `<h6>Renotify <strong class="text-success">${approverName}</strong> ?</h6>`,
        confirmButtonText: 'Renotify',
        preConfirm: () => new Promise(resolve => {
          UserExpensesPRPendingService.show(paymentRequest.id).then(
            ({ data: { message } }) => {
              if (this.currentTabIndex === 0) {
                this.tablePendingRefresh()
              }
              this.swalSuccess(message)
              resolve(message)
            }
          ).catch(
            error => resolve(error.message)
          )
        })
      })
    },

    onPost (paymentRequest) {
      return new Promise((resolve, reject) => {
        UserExpensesPRPendingService.post(paymentRequest).then(
          ({ data }) => {
            if (this.currentTabIndex === 0) {
              this.tablePendingRefresh()
            }
            resolve(data)
          }
        ).catch(error => reject(error.message))
      })
    },

    onPut (paymentRequest) {
      return new Promise((resolve, reject) => {
        UserExpensesPRPendingService.put(paymentRequest).then(
          ({ data }) => {
            if (this.currentTabIndex === 0) {
              this.tablePendingRefresh()
            }
            resolve(data)
          }
        ).catch(error => reject(error.message))
      })
    },

    tablePendingRefresh () {
      if (this.currentTabIndex === 0) {
        this.$refs.tablePending.refresh()
      }
    }
  }

}
</script>
